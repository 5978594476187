.clean-layout {
  .clean-layout-heading {
    padding-top: var(--padding-large);
    .clean-layout-heading-block {
      max-width: var(--content-block-max-width);
      margin: auto;
      text-align: center;
    }
  }
}

@media (max-width: 576px) {
  .clean-layout {
    .clean-layout-heading {
      padding-top: var(--padding-large);
      .clean-layout-heading-block {
        padding: 0 var(--padding-regular);
      }
    }
  }
}