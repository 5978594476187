.footer {
  position: relative;
  height: var(--footer-height);
  color: var(--color-light);
  background-color: var(--color-secondary);
  .footer-content-block {
    width: 100%;
    max-width: var(--content-block-max-width);
    margin: auto;
    padding: var(--padding-regular) 0;
    display: flex;
    flex-wrap: wrap;
    &.narrow {
      padding: var(--padding-small) 0;
    }
    .footer-content-slot {
      box-sizing: border-box;
      flex-grow: 1;
      &.slot-1 {
        flex-basis: 0;
      }
      &.slot-1,
      &.slot-2 {
        width: 15%;
      }
      &.slot-3,
      &.slot-4 {
        width: 30%;
      }
      h3 {
        margin: var(--padding-small) 0;
        font-size: 16px;
      }
    }
    .footer-content-bottom-slot {
      box-sizing: border-box;
      flex-grow: 1;
      &.slot-1,
      &.slot-2 {
        width: 50%;
        padding: var(--padding-extra-small) 0;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: var(--padding-small) 0;
    li {
      font-size: 0.85rem;
      padding: var(--padding-extra-small) 0;
      color: var(--color-grey3);
    }
  }
  .footer-social-icons {
    span {
      display: inline-block;
      &:not(:last-child) {
        margin-right: var(--padding-regular);
      }
      img {
        filter: invert(1);
        cursor: pointer;
      }
    }
  }
  .footer-logo {
    margin-top: var(--padding-small);
    padding-right: var(--padding-regular);
  }
  .footer-payments-title {
    display: flex;
    align-items: center;
    svg {
      margin-right: var(--padding-small);
    }
  }
  .footer-payments-methods {
    padding: var(--padding-small) 0;
    img {
      max-width: 100%;
    }
  }
  .footer-copyright {
    background-color: var(--color-secondary-dark);
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 0.8rem;
    a {
      margin-left: var(--padding-small);
    }
    .footer-disclaimer {
      padding: var(--padding-extra-small) 0;
      font-size: 0.65rem;
    }
  }
}

@media (max-width: 820px) {
  .footer {
    height: auto;
    .footer-content-block {
      padding: var(--padding-regular);
    }

    .footer-copyright {
      padding: var(--padding-regular) var(--padding-regular) var(--padding-large) var(--padding-regular);
      position: relative;
    }
  }
}

@media (max-width: 576px) {
  .footer {

    .footer-content-block {
      .footer-content-slot {
        &.slot-1,
        &.slot-2,
        &.slot-3,
        &.slot-4 {
          width: 100%;
          padding-bottom: var(--padding-regular);
        }
      }
      .footer-content-bottom-slot {
        text-align: center;
        &.slot-1,
        &.slot-2 {
          width: 100%;
        }
      }
    }

    .footer-copyright {
      position: relative;
      a {
        margin-left: 0;
        display: block;
        padding: var(--padding-small) 0;
      }
      .footer-disclaimer {
        padding: var(--padding-extra-small) 0;
        font-size: 0.65rem;
      }
    }
  }
}

@media (prefers-color-scheme: dark) {}