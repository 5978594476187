.column-splitted-layout {
  --sidebar-width: 240px;
  display: flex;
  flex-direction: row;
  min-height: 300px;
  max-width: var(--content-block-max-width);
  margin: auto;

  .column-splitted-layout-sidebar {
    position: sticky;
    top: calc(var(--navbar-height) + var(--padding-regular));
    height: 100%;
    width: var(--sidebar-width);
    padding-right: var(--padding-regular);
  }

  .column-splitted-layout-content-wrapper {
    height: 100%;
    width: calc(100% - var(--sidebar-width));
    padding-left: calc(var(--padding-regular) * 1.5);
    border-left: 1px solid var(--color-light);
  }
}

@media (max-width: 820px) {
  .column-splitted-layout {
    display: block;

    .column-splitted-layout-sidebar {
      position: relative;
      width: 100%;
      top: 0;
      padding-right: 0;
      border-bottom: 1px solid var(--color-light);
      margin-bottom: 0;
    }

    .column-splitted-layout-content-wrapper {
      height: 100%;
      width: 100%;
      padding-left: 0;
      border-left: none;
    }

    &.column-splitted-layout-sidebar-hidden {
      .column-splitted-layout-sidebar {
        display: none;
      }
    }
  }
}