.dropdown-filterable {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cbd6e2;
  border-radius: var(--border-radius-small);
  padding-right: var(--padding-small);

  input {
    border: none !important;
    background-color: transparent;
    margin: 2px;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  img {
    cursor: pointer;
  }

  &.is-active {
    outline: none;
    border: 1px solid #307ee1;
    box-shadow: 0 0 0px 2px white;
  }

  .dropdown-filterable-panel {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    min-width: 100%;
    max-width: 400px;
    background-color: white;
    z-index: 999;
    border: 1px solid #cbd6e2;
    border-radius: var(--border-radius-small);
    box-shadow: var(--box-shadow);
    padding: calc(var(--padding-small) / 2);
    max-height: 400px;
    overflow-y: auto;
    display: none;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: calc(var(--padding-small) / 2);
        border-radius: var(--border-radius-small);

        &:hover {
          background-color: #307ee1;
          color: white;
          cursor: pointer;
        }
      }
    }

    &.dropdown-filterable-panel-is-active {
      display: block;
    }
  }
}

@media (max-width: 1200px) {}

@media (max-width: 820px) {}

@media (max-width: 576px) {
  .dropdown-filterable {
    width: 100%;

    .dropdown-filterable-panel {
      min-width: unset;
      width: 100%;
      max-height: 250px;
    }

  }
}