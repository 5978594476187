.modal {
  --modal-size: 75vh;

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: .75);
  opacity: 0;
  z-index: 999;

  &.modal-small {
    --modal-size: 50vh;
  }

  &.animate-in {
    animation-name: animateIn;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    .modal-container {
      animation-name: animateContainerIn;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
  }

  .modal-container {
    position: absolute;
    height: var(--modal-size);
    width: var(--modal-size);
    left: calc(50vw - calc(var(--modal-size) * .5));
    top: calc(50vh - calc(var(--modal-size) * .5));
    transform: translateY(100%);
    opacity: .5;
    background-color: var(--color-white);
    border-radius: var(--border-radius-large);
    display: grid;
    grid-auto-rows: min-content auto max-content;

    .modal-header {
      padding: var(--padding-regular);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      background-color: #e9e9ed;
      border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
      border-bottom: 2px solid var(--color-primary);

      h3, p {
        font-size: 1.5rem;
        color: var(--color-secondary);
        margin: 0;
      }

      h3 {
        font-weight: 500;
      }

      p {
        font-size: .9rem;
        color: var(--color-grey2);
        padding: var(--padding-extra-small) 0;
      }

      .close-icon {
        cursor: pointer;
      }
    }

    .modal-content {
      padding: var(--padding-regular);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: var(--color-grey3);
        border-radius: 999px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--color-grey2);
        border-radius: 999px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--color-dark);
      }

      form {
        h4 {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .modal-footer {
      padding: var(--padding-regular);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #e9e9ed;
      border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
    }
  }
}

@keyframes animateContainerIn {
  0% {
    transform: translateY(100%);
    opacity: .5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animateIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 821px) and (max-width: 1367px) {
  .modal {
    --modal-size: 90vh;

    &.modal-small {
      --modal-size: 75vh;
    }
  }
}

@media (max-width: 820px) {
  .modal {
    --modal-size: 100vw;

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--modal-size);
    z-index: 999999;

    &.modal-small {
      --modal-size: 100vw;
    }

    .modal-container {
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 0;

      .modal-footer {
        border-radius: 0;
      }
    }
  }
}