.tab-selector {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content auto;
  margin-bottom: var(--padding-small);
  .tab-button {
    padding: var(--padding-small);
    border-bottom: 2px solid var(--color-grey5);
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
    }
    &.is-active {
      border-bottom: 2px solid var(--color-primary);
    }
  }
}