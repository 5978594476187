.password-input-with-toggle {
  width: 100%;
  position: relative;
  z-index: 1;

  input {
    padding-right: 40px;
  }

  .password-input-with-toggle-button {
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
    z-index: 2;
    height: 24px;
    width: 24px;
    cursor: pointer;
    opacity: .5;
    transition: all .2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}