.navbar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--color-white);
  height: var(--navbar-height);
  .navbar-content-block {
    background-color: var(--color-white);
    width: 100%;
    max-width: var(--content-block-max-width);
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .navbar-content-slot {
      display: flex;
      align-items: center;
    }
    .navbar-content-slot-left {
      justify-content: flex-start;
      flex: 1;
    }
    .navbar-content-slot-right {
      justify-content: flex-end;
    }
    .navbar-items {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      flex-wrap: nowrap;
      list-style: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      li {
        display: flex;
        align-items: center;
        color: var(--color-grey2);
        white-space: nowrap;
        &.has-separator {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 11px;
            bottom: 8px;
            width: 2px;
            background: var(--color-grey-light);
          }

          .navbar-link {
            padding: 0 var(--padding-small);
          }
          .dropdown {
            padding: 0 var(--padding-small);
          }
        }
        .navbar-link {
          padding: var(--padding-extra-small) calc(var(--padding-small) * 1.5);
          display: flex;
          align-items: center;
          height: 100%;
        }
        .dropdown {
          position: relative;
          padding: var(--padding-small);
          height: 100%;
          cursor: pointer;
          .dropdown-summary {
            list-style: none;
          }
          .dropdown-button {
            display: flex;
            align-items: center;
            text-decoration: none;
          }
          .dropdown-arrow {
            display: inline-block;
            margin-left: var(--padding-small);
            margin-bottom: -3px;
          }
          .dropdown-content {
            position: absolute;
            top: calc(100% + var(--padding-small));
            left: var(--padding-small);
            background-color: #fff;
            padding: var(--padding-small) 0;
            box-shadow: var(--box-shadow);
            border-radius: 10px;
            opacity: 0;
            transform: translatey(-20px);
            ul {
              list-style: none;
              padding: 0;
              li {
                white-space: nowrap;

                a, span {
                  display: block;
                  width: 100%;
                  padding: var(--padding-small) var(--padding-regular);
                }

                &:hover {
                  color: var(--color-dark);
                }

                &.is-divider {
                  padding: 0;
                  margin: var(--padding-small) 0;
                  border-bottom: 1px solid var(--color-grey3);
                }
              }
            }
          }
        }
      }
    }
  }
}

.shop-cart-icon-wrapper {
  position: relative;
  height: 100%;
  width: 18px;
  padding: var(--padding-extra-small) 0;
  margin: 0 var(--padding-regular);

  img {
    position: absolute;
    top: calc(50% - 9px);
  }

  .shop-cart-icon-indicator {
    position: absolute;
    top: calc(50% - 15px);
    right: -8px;
    height: 14px;
    width: 14px;
    font-size: 10px;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    border-radius: 999px;
  }
}

.navbar-mobile {
  display: none;
}

.navbar-mobile-burger {
  display: none;
}

@media (max-width: 1200px) {
  .navbar {
    .navbar-content-block {
      padding: 0 var(--padding-regular);
    }
  }
}

@media (max-width: 820px) {

  .navbar-mobile-burger {
    display: block;
    padding: 8px;
  }

  .navbar-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 100vw;
    width: 40vw;
    height: 100vh;
    background-color: #fff;
    z-index: 999999;
    transition: left .25s ease-in-out;
    border-left: 1px solid var(--color-grey3);

    &.navbar-mobile-show {
      left: 60vw;
    }

    .navbar-mobile-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--navbar-mobile-header-height);
      padding: 0 var(--padding-regular);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .navbar-mobile-header-close {
        cursor: pointer;
      }

      .shop-cart-icon-wrapper {
        margin: 0;
      }
    }

    .navbar-mobile-scroller {
      position: absolute;
      top: var(--navbar-mobile-header-height);
      left: 0;
      bottom: 0;
      width: 100%;
      overflow-y: auto;
      overscroll-behavior: contain;

      .navbar-mobile-items {
        list-style: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        padding: var(--padding-regular);
        margin: 0;

        li {
          padding-bottom: var(--padding-regular);
          font-weight: 600;
          font-size: 20px;

          h3 {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-size: 20px;

            span {
              display: flex;
              align-items: center;
            }

            &.large {
              font-size: 26px;
              font-weight: 700;
            }

            .dropdown-arrow {
              transition: transform 0.2s ease-out;

              &.chevron-down {
                transform: rotate(180deg);
              }
            }
          }

          a {
            display: block;
          }

          &:last-child {
            padding-bottom: 0;
          }

          button {
            width: 100%;
          }

          .navbar-mobile-items-language-flag {
            height: 18px;
            width: 26px;
            object-fit: cover;
            margin-right: 8px;
            border-radius: 3px;
          }
        }

        .navbar-mobile-sub-item {
          padding-left: var(--padding-small);
          font-weight: 400;
          display: flex;
          align-items: center;
        }
      }

      .navbar-mobile-divider {
        margin: var(--padding-small) var(--padding-regular) 0 var(--padding-regular);
        padding-bottom: var(--padding-small);
        border: none;
        border-top: 1px solid var(--color-grey3);
      }
    }
  }

  .navbar {
    .navbar-content-block {
      .navbar-items {
        display: none;
      }
      .navbar-content-slot-left {
        justify-content: space-between;
      }
    }
  }

}

@media (max-width: 576px) {
  .navbar-mobile {
    width: 100vw;
    border-left: none;

    &.navbar-mobile-show {
      left: 0;
    }
  }
}

@media (prefers-color-scheme: dark) {}

/** Animations */
.dropdown-content-animated {
  animation: animateDown 0.1s ease-in-out forwards;
}

@keyframes animateDown {
  to {
    opacity: 1;
    transform: translatey(0);
  }
}