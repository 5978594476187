.content-sidebar-bookmarks {
  padding: var(--padding-regular) 0;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      padding: var(--padding-extra-small) var(--padding-small);
      border-radius: var(--border-radius-small);
      margin-bottom: var(--padding-small);
      cursor: pointer;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--color-text-secondary);
        font-weight: 500;
        font-size: .9rem;
        text-transform: uppercase;
        svg {
          height: auto;
          width: 16px;
          margin-right: 8px;
          path {
            fill: var(--color-text-secondary);
          }
        }
      }

      &.is-active {
        &::before {
          content: "";
          position: absolute;
          z-index: 999;
          right: 0;
          top: 0;
          height: 100%;
          width: 4px;
          border-radius: var(--border-radius-small);
          background: var(--color-primary);
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .content-sidebar-bookmarks {
    padding: var(--padding-regular);

    ul {
      li {
        padding: var(--padding-extra-small) 0;
        &.is-active {
          &::before {
            width: 0px;
            border-radius: 0;
            background: none;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {}