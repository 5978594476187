.formLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: var(--padding-small) 0;

  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--color-text-secondary);
  }
  .inputWrapper {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      height: 20px;
    }
  }

  input,
  select {
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: var(--padding-small);
    border: 1px solid #cbd6e2;
    border-radius: var(--border-radius-small);
    .noBorder {
      border: none;
    }
    &[type='checkbox'] {
      display: block;
      border-radius: 5px;
      width: 200px;
    }
    // focus
    &:focus {
      outline: none;
      border: 1px solid #307ee1;
      box-shadow: 0 0 0px 2px white;
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // dropdown arrow
    background-image: url('/assets/images/icons/InputSelectArrowIcon.svg');
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    option {
      margin: 100px 10px;
    }
  }
}

.formError {
  color: var(--color-red-dark);
  margin-bottom: 8px;
  span {
    margin: 0;
    font-size: .9rem;
    font-weight: 500;
  }
}

.dropdownSelect {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #cbd6e2;
  border-radius: var(--border-radius-small);
  padding: 5px 5px;
  height: 40px;
}

.countryDropdown {
  box-shadow: rgb(25 28 31 / 10%) 0px 0.1875rem 0.5rem 0px;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  top: 90px;
  background-color: white;

  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  .countryDropdownList {
    position: absolute;
    background-color: white;
    transition: all 0.2s ease-in-out;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-grey3);
      border-radius: 999px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-grey2);
      border-radius: 999px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--color-dark);
    }
  }
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0px 15px;
    background-color: white;
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .countryDetails {
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: #cbd6e2;
    }
  }
}

.flagContainer {
  margin-right: 10px;
  position: relative;
  height: 15px;
  width: 25px;
  overflow: hidden;
  background-color: #cbd6e2;
  border-radius: 3px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}