.formWrapper {
  display: flex;
  max-width: 250px;
  padding: var(--padding-small) 0px;
  flex-direction: column;
  width: 100%;
  .inputWrapper {
    display: flex;
    input[type='checkbox'] {
      height: 20px;
    }
  }
  input,
  select {
    max-width: 250px;
    width: 100%;
    padding: var(--padding-small);
    border: 1px solid #cbd6e2;
    border-radius: var(--border-radius-small);
    margin: 10px 0px 0px;
    &[type='checkbox'] {
      display: block;
      width: 200px;
    }
  }
  select {
    option {
      margin: 100px 10px;
    }
  }
}