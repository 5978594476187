.toast {
  --toast-width: 50vw;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  padding: var(--padding-small);
  font-weight: 500;
  border-radius: var(--border-radius-small);
  width: var(--toast-width);
  left: calc(50vw - calc(var(--toast-width) * .5));
  bottom: var(--padding-regular);
  transform: translateY(100%);
  opacity: .5;
  background-color: var(--color-white);
  border-radius: var(--border-radius-large);
  &.animate-in {
    animation-name: animateToastIn;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
  &.neutral {
    background-color: var(--color-neutral);
    color: #222;
    .toast-icon {
      svg {
        path {
          fill: var(--color-neutral);
        }
      }
    }
  }
  &.danger {
    background-color: var(--color-danger);
    color: var(--color-white);
    .toast-icon {
      svg {
        path {
          fill: var(--color-danger);
        }
      }
    }
  }
  &.success {
    background-color: var(--color-success);
    color: #222;
    .toast-icon {
      svg {
        path {
          fill: var(--color-success);
        }
      }
    }
  }
  .toast-icon {
    padding-right: var(--padding-regular);
    svg {
      display: block;
      height: auto;
      width: 42px;
    }
  }
}

@keyframes animateToastIn {
  0% {
    transform: translateY(100%);
    opacity: .5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}