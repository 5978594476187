.message {
  display: flex;
  align-items: center;
  padding: var(--padding-extra-small) var(--padding-small);
  border-radius: var(--border-radius-small);

  &.message-dark-mode {
    border: 2px solid #fff;
  }

  &.subtle {
    background-color: var(--color-grey4);
    .toast-icon {
      svg {
        path {
          fill: var(--color-neutral);
        }
      }
    }
  }
  &.neutral {
    background-color: var(--color-neutral-alt);
    .toast-icon {
      svg {
        path {
          fill: var(--color-neutral);
        }
      }
    }
  }
  &.danger {
    background-color: var(--color-danger-alt);
    .toast-icon {
      svg {
        path {
          fill: var(--color-danger);
        }
      }
    }
  }
  &.success {
    background-color: var(--color-success-alt);
    .toast-icon {
      svg {
        path {
          fill: var(--color-success);
        }
      }
    }
  }
  .message-icon {
    display: block;
  }

  .message-content {
    padding-left: var(--padding-small);
  }
}