.contact {
  width: 100%;

  &.dark-mode {
    label {
      color: var(--color-white);
    }
  }

  .contact-container {
    display: flex;
    flex-direction: column;

    .contact-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .contact-textarea {
      textarea {
        font-family: inherit;
        resize: none;
        padding: var(--padding-small);
        font-size: 16px;
        border-radius: 5px;
        min-height: 150px;
        border: 1px solid #cbd6e2;
      }
    }
  }
}

@media (max-width: 576px) {
  .contact {

    .contact-container {

      .contact-inputs {
        grid-template-columns: 1fr;
      }
    }
  }
}